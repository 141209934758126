<template>
  <div class="card card-custom">
    <div class="card-header mt-5 d-flex flex-column flex-sm-row justify-content-between ">
    <div class="card-title d-flex  mb-3 mb-sm-0">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary"></i>
      </span>
      <h3 class="card-label ml-2 font-poppins">All Vouchers</h3>
    </div>
    <v-row class="align-items-center justify-content-end no-gutters w-100">
      <v-col cols="12" sm="auto" v-if="isAnyFilterApplied" class="d-flex justify-end px-2 mb-2">
        <span class="text-primary cursor-pointer" @click="clearFilters">Clear Filters</span>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2">
        <v-text-field
          v-model="businessName"
          outlined
          dense
          hide-details
          placeholder="Filter by Business Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2 d-flex justify-end">
        <v-btn elevation="2" class="bg-primary text-light font-poppins"  @click="loadVouchers">Search</v-btn>
      </v-col>
    </v-row>
  </div>
    <div class="card-body p-0">
   
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="vouchers"
            :items-per-page="itemsPerPage"
            :page.sync="currentPage"
            :server-items-length="totalVouchers"
            :loading="loading"
            class="elevation-1"
            :footer-props="{'items-per-page-options': [5, 10, 15, 25, 50]}"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item.id)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="loadVouchers"
            class="mt-4"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "vouchers",
  data() {
    return {
      headers: [
        { text: "Store Name", align: "left", sortable: false, value: "business.store_name" },
        { text: "Business Name", align: "left", sortable: false, value: "business.name" },
        { text: "Code", align: "left", sortable: false, value: "code" },
        { text: "Discount Type", align: "left", sortable: false, value: "discount" },
        { text: "Percentage Discount", align: "left", sortable: false, value: "percentage_discount" },
        { text: "Amount Discount", align: "left", sortable: false, value: "amount_off" },
        { text: "Products", align: "left", sortable: false, value: "free_products" },
        { text: "Type", align: "left", sortable: false, value: "type" },
        { text: "Start Date", align: "left", sortable: false, value: "start_date" },
        { text: "End Date", align: "left", sortable: false, value: "end_date" },
        { text: "Usage Allowed", align: "left", sortable: false, value: "customer_counts" },
        { text: "Minimum Amount", align: "left", sortable: false, value: "minimum_amount" },
        { text: "Discount on Toppings", align: "left", sortable: false, value: "discount_product_toppings" },
        { text: "Discount on Properties", align: "left", sortable: false, value: "discount_product_properties" },
        { text: "Opening Timings", align: "left", sortable: false, value: "opening_timings" }
      ],
      vouchers: [],
      businessName: "",
      currentPage: 1,
      itemsPerPage: 5,
      totalVouchers: 0,
      loading: false
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalVouchers / this.itemsPerPage);
    },
    isAnyFilterApplied() {
      return this.businessName !== "";
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Vouchers" }]);
    this.loadVouchers();
  },
  methods: {
    clearFilters() {
      this.businessName = "";
      this.loadVouchers();
    },
    loadVouchers() {
      this.loading = true;
      ApiService.post("admin/voucher-codes", {
     
          business_name: this.businessName,
          page: this.currentPage,
          per_page: this.itemsPerPage
        
      }).then(({ data }) => {
        this.vouchers = data.data;
        this.totalVouchers = data.total;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    edit(id) {
      // Handle edit action
    }
  }
};
</script>
